<template>
  <div class="customer-list-add" :style="{height:freshHeight +'px','overflow-y':'scroll','box-sizing': 'border-box'}">
    <van-form @submit="saveCustomerInfo()" alidate-first @failed="onFailed" :rules="customerForm">
      <div class="baseInfo">
        <van-row type="flex" justify="center" align="center" class="card">
          <van-col span="24">
            <van-field v-model="customerInfo.customerName" :rules="customerForm.customerName" input-align="right"
                       placeholder="请填写客户名称"
                       label="*客户名称："/>
            <van-row v-if="this.enterpriseTypeItems">
              <van-field
                  readonly
                  clickable
                  label="*企业类型："
                  input-align="right"
                  :value="customerInfo.enterpriseType"
                  :rules="customerForm.enterpriseType"
                  placeholder="请选择企业类型"
                  @click="onClickType"
              />
              <van-popup v-model="showEnterpriseTypePicker" round position="bottom">
                <van-picker
                    show-toolbar
                    :columns="this.enterpriseTypeItems"
                    @cancel="showEnterpriseTypePicker = false"
                    @confirm="onConfirmType"
                    default-index="0"
                />
              </van-popup>
            </van-row>
            <van-row v-if="this.enterpriseNatureItems">
              <van-field
                  readonly
                  clickable
                  label="*企业性质："
                  input-align="right"
                  :value="customerInfo.enterpriseNature"
                  :rules="customerForm.enterpriseNature"
                  placeholder="请选择企业性质"
                  @click="onClickNature"
              />
              <van-popup v-model="showEnterpriseNaturePicker" round position="bottom">
                <van-picker
                    show-toolbar
                    :columns="this.enterpriseNatureItems"
                    @cancel="showEnterpriseNatruePicker = false"
                    @confirm="onConfirmNature"
                    default-index="0"
                />
              </van-popup>
            </van-row>
            <van-field v-model="customerInfo.customerLevels" readonly="readonly"
                       input-align="right"
                       label=" 授信等级："/>
          </van-col>
        </van-row>
      </div>
      <div class="conInfo">
        <van-row>
          <van-col span="12">
            <div>
              <van-field v-model="customerInfo.contacts" label="姓名 :"/>
            </div>
          </van-col>
          <van-col span="12">
            <div>
              <van-field v-model="customerInfo.contactsPost" label="职务 ："/>
            </div>
          </van-col>
        </van-row>
        <van-row>
          <div>
            <van-field span="24" v-model="customerInfo.contactsPhone" label="电话 :"/>
          </div>
        </van-row>
        <van-row>
          <div>
            <van-field span="24" v-model="customerInfo.addressInfo.addressDetailed" label="地址" @click="showMap"/>
            <van-field span="24" v-model="customerInfo.addressInfo.addressLongitude" v-show="false" label="经度 :"
                       type="number"/>
            <van-field span="24" v-model="customerInfo.addressInfo.addressLatitude" v-show="false" label="纬度 :"
                       type="number"/>
          </div>
        </van-row>
      </div>
      <div class="moreAddress">
        <van-cell is-link @click="showAdrress()" :title=addressName title-class="moreAddress-text"></van-cell>
      </div>
      <van-row v-if="customerInfo.enterpriseType == '港方'">
        <van-field
            readonly
            clickable
            label="*经营性质："
            input-align="right"
            :value="customerInfo.businessNature"
            :rules="customerForm.businessNature"
            placeholder="请选择经营性质"
            @click="onClickBusinessNature"
        />
        <van-popup v-model="showBusinessNaturePicker" round position="bottom">
          <van-picker
              show-toolbar
              :columns="this.businessNatureItems"
              @cancel="showBusinessNaturePicker = false"
              @confirm="onConfirmBusinessNature"
              default-index="0"
          />
        </van-popup>
      </van-row>
      <div class="remark">
        <van-field
            v-model="customerInfo.remarks"
            rows="2"
            autosize
            label="备注"
            type="textarea"
            maxlength="50"
            placeholder="请输入备注"
            show-word-limit
        />
      </div>
      <van-button type="primary" size="large" native-type="submit">保存</van-button>
      <!-- 底部导航栏-->
      <crm-tab-bar :tabpage="5"/>

      <!-- 百度地图位置选择 -->
      <BMapAddressSelect :addressDetailInfo="this.customerInfo.addressInfo" ref="bmapAddressSelect"
                         @confirmMapAddress="confirmMapAddress"></BMapAddressSelect>
    </van-form>
  </div>

</template>

<script>

import BMapAddressSelect from "@/components/BMapAddressSelect/index";
import CrmTabBar from "@/components/CrmTabBar";

export default {
  name: "CustomerListAdd",
  components: {CrmTabBar, BMapAddressSelect},
  data() {
    return {
      //客户信息
      customerInfo: {
        customerName: '',
        enterpriseType: '',
        enterpriseNature: '',
        customerGrade: '',
        customerLevels: '',
        contactsPost: '',
        contacts: '',
        contactsPhone: '',
        businessNature: '',
        remarks: '',
        addressInfo: {
          addressDetailed: '',
          addressLongitude: '',
          addressLatitude: '',
          addressProvince: '',
          addressCity: '',
        }
      },
      showEnterpriseTypePicker: false,
      showEnterpriseNaturePicker: false,
      showBusinessNaturePicker: false,
      enterpriseTypeItems: [
        '货方', '港方', '船方', '其他'
      ],
      enterpriseNatureItems: [],
      businessNatureItems: [
        '国企', '民营'
      ],
      customerForm: {
        customerName: [
          {required: true, message: '请输入客户名称', trigger: 'blur'},
        ],
        enterpriseType: [
          {required: true, message: '请选择客户类型', trigger: 'blur'},
        ],
        enterpriseNature: [
          {required: true, message: '请选择客户性质', trigger: 'blur'},
        ],
        businessNature: [
          {required: true, message: '请选择经营性质', trigger: 'blur'},
        ],
        contactsPhone: [
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的电话",
            trigger: "blur",
          },
        ],
      },
      //列表高度
      freshHeight: 0,
      addressName: '更多地址',
    }
  },
  mounted() {
    this.init()
  },
  beforeRouteEnter(to, from, next) {
    if (from.path.includes("/BulkCrmsCusAddressDetailListLs")) {
      next(vm => {
        // beforeRouteEnter不能通过this访问组件实例，但是可以通过 vm 访问组件实例
        vm.customerInfo = JSON.parse(sessionStorage.getItem('customerInfo'))
        console.log(vm.customerInfo)
      })
      to.meta.isBack = true;
    } else {
      to.meta.isBack = false;
    }
    next();
  },
  methods: {
    init() {
      this.freshHeight = document.documentElement.clientHeight - 50
    },
    showAdrress() {
      sessionStorage.setItem('customerInfo', JSON.stringify(this.customerInfo))
      this.$router.push({path: '/BulkCrmsCusAddressDetailListLs/' + this.$route.params.name})
    },
    saveCustomerInfo() {
      console.log(this.customerInfo)
      //校验是否为已有客户
      this.checkCustomerName().then(res => {
        console.log(res)
        if (res) {
          this.$ajax.post('/api/ajax/bulkCrmsCusList/saveBulkCrmsCustomertwo.json',
              {
                customerName: this.customerInfo.customerName,
                addressOtherid: this.$route.params.name,
                enterpriseType: this.customerInfo.enterpriseType,
                enterpriseNature: this.customerInfo.enterpriseNature,
                customerLevels: this.customerInfo.customerLevels,
                customerGrade: this.customerInfo.customerGrade,
                remarks: this.customerInfo.remarks,
                contactsPhone: this.customerInfo.contactsPhone,
                contacts: this.customerInfo.contacts,
                contactsPost: this.customerInfo.contactsPost,
                addressDetailed: this.customerInfo.addressInfo.addressDetailed,
                addressProvince: this.customerInfo.addressInfo.addressProvince,
                addressCity: this.customerInfo.addressInfo.addressCity,
                addressLongitude: this.customerInfo.addressInfo.addressLongitude,
                addressLatitude: this.customerInfo.addressInfo.addressLatitude,
                customerGm: this.$store.getters.userId,
                createdPeople: this.$store.getters.userId,
                businessNature: this.customerInfo.businessNature,
              }).then(res => {
            if (res.status == 200) {
              this.$message({
                showClose: true,
                message: '添加成功',
                type: 'success'
              });
              sessionStorage.clear();
              setTimeout(() => {
                this.$router.push({path: '/CustomerList'})
              }, 200);
            }
          }).catch(err => {
            console.log(err)
            this.$message.error('添加失败');
          })
        } else {
          this.$message.warning('该客户已存在！');
        }
      })
    },
    onFailed(errorInfo) {
      console.log('failed', errorInfo);
    },
    onClickType() {
      this.showEnterpriseTypePicker = true
      this.customerInfo.businessNature = ''
    },
    onClickNature() {
      this.showEnterpriseNaturePicker = true
    },
    onClickBusinessNature() {
      this.showBusinessNaturePicker = true
    },
    onConfirmType(picker, value, index) {
      console.log(picker, value, index);
      this.customerInfo.enterpriseType = picker;
      this.showEnterpriseTypePicker = false;
      switch (picker) {
        case '货方':
          this.enterpriseNatureItems = [
            '',
            '生产型',
            '贸易型',
            '同行业',
          ]
          this.customerInfo.enterpriseNature = this.enterpriseNatureItems[0]
          break
        case '船方':
          this.enterpriseNatureItems = [
            '',
            '船东',
            '船东代理',
            '其他',
          ]
          this.customerInfo.enterpriseNature = this.enterpriseNatureItems[0]
          break
        case '港方':
          this.enterpriseNatureItems = [
            '',
            '港口',
            '港口代理',
            '其他',
          ]
          this.customerInfo.enterpriseNature = this.enterpriseNatureItems[0]
          break
        case '其他':
          this.enterpriseNatureItems = [
            '',
            '其他',
          ]
          this.customerInfo.enterpriseNature = this.enterpriseNatureItems[0]
          break
      }
      console.log(this.enterpriseNatureItems);
    },
    onConfirmNature(picker, value, index) {
      console.log(picker, value, index);
      this.customerInfo.enterpriseNature = picker;
      this.showEnterpriseNaturePicker = false;
    },
    onConfirmBusinessNature(picker, value, index) {
      console.log(picker, value, index);
      this.customerInfo.businessNature = picker;
      this.showBusinessNaturePicker = false;
    },
    /** 显示地图 */
    showMap() {
      if (this.customerInfo.addressInfo.addressDetailed.length > 0) {

      } else {
        this.$refs.bmapAddressSelect.show();
      }
    },
    /** 确认地图地址 */
    confirmMapAddress(addressDetailInfo) {
      this.customerInfo.addressInfo = addressDetailInfo;
    },
    checkCustomerName() {
      return new Promise((resolve, reject) => {
        this.$ajax.post('/api/ajax/bulkCrmsCusList/checkCustomerName.json',
            {
              customerName: this.customerInfo.customerName,
            }).then(res => {
          resolve(res.data.length <= 0)
        }).catch(error => {
          reject(error)
        })
      })
    }
  },


}

</script>

<style scoped>

.customer-list-add .card {
  margin: 10px 5px;
  background: #378FF2;
  border-radius: 6px;
  box-shadow: 6px 6px 5px #888888;
}

.customer-list-add .conInfo {
  margin: 10px 5px;
  padding: 5px;
  border: 1px solid #5CA4F4;
  border-radius: 6px;
  background: white;
  box-shadow: 6px 6px 5px #888888;
}

.customer-list-add .remark {
  margin: 8px 0px;
  padding: 3px;
  border-radius: 5px;
  background: white;
}

.customer-list-add .moreAddress {
  margin: 5px 0px;
  color: #5CA4F4;
  text-align: left;
}

</style>
<!--样式穿透 不能在<style scoped>中修改已给定样式，可以使用样式穿透-->
<style>

.customer-list-add .baseInfo .van-field__control--right {
  color: #5CA4F4;
  font-size: 13px;
}

.customer-list-add .cusInfo .van-field, .qtyPrf .van-field, .conInfo .van-field {
  padding-top: 3px;
  height: 30px;
  font-size: 12px;
}


.customer-list-add .baseInfo .van-field, .hlnx .van-field, .jbxx .van-field {
  padding-top: 3px;
  height: 30px;
  font-size: 12px;
}

.customer-list-add .remark .van-field, .ejgs .van-field {
  padding-top: 3px;
  font-size: 12px;
}

.customer-list-add .moreAddress .van-cell {
  color: #5CA4F4;
  font-size: 12px;
}

.customer-list-add .van-button {
  width: 220px;
  height: 35px;
  border-radius: 5px;
  font-weight: bold;
  font-family: 新宋体;
}

.customer-list-add .hlnx {
  margin: 10px 0px;
  padding: 5px;
  border: 1px solid #5CA4F4;
  border-radius: 5px;
  background: white;
}

</style>


